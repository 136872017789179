export const ADD_TO_CART = "ADD_TO_CART";
export const IN_QTY = "IN_QTY";
export const DEC_QTY = "DEC_QTY";
export const REMOVE_QTY = "REMOVE_QTY";
export const CLEAR_QTY = "CLEAR_QTY";
export const CLEAR_CART = "CLEAR_CART";

export const GET_CART_R = "GET_CART_R";
export const GET_CART_S = "GET_CART_S";
export const GET_CART_F = "GET_CART_F";
