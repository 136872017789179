import { Button, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_Attendance, profileUpdate } from "../../redux/AuthReducer/action";
import { getLocalData } from "../../utils/localStorage";
import "./cal.css";
import { ccAvanue } from "../rozarpay/RozarPay";
export default function Calendar() {
  const [CalendarArray, setCalendarArray] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});
  const subscribeProducts = useSelector(
    (store) => store.pagesReducer.subscribeProducts
  );
  const token = getLocalData("token"); //different approaches for getting local storage
  const email = getLocalData("userInfo");
  const payload = {
    email: email,
    token,
  };
  const profile = useSelector((store) => store?.AuthReducer?.profileData);
  useEffect(() => {
    var noOfDays = GET_Attendance(token.uid).then((res) => {
      ReduceArray(res);
    });

    var data = subscribeProducts.find((ele) => ele.id === profile.subscribedID);
    console.log("🚀 ~ file: Calendar.js:26 ~ useEffect ~ data:", data);
    setCurrentProduct(data);
  }, []);

  const ReduceArray = (data) => {
    const perChunk = 7;
    var inputArray = data;
    var noOfDays = data?.filter((ele) => ele.delivered);
    const result = inputArray?.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
    setCalendarArray({ result, noOfDays });
    console.log("data", result);
    return result;
  };
  const profileData = useSelector((state) => state.AuthReducer?.profileData);
  const cart = useSelector((store) => store.cart.cart);

  console.log(profileData, cart);
  const dispatch = useDispatch()
  const ProceedForOrder = (res) => {
    var loc =
      window.location.hostname === "localhost"
        ? "AVBE21KB97BJ46EBJB"
        : "AVIE16KB40AF84EIFA";

    var action_url =
      window.location.hostname === "localhost"
        ? "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
        : "https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction";
    var f = document.createElement("form");
    f.action = action_url;

    f.method = "POST";
    f.redirect = "redirect";
    f.id = "nonseamless";

    var i = document.createElement("input");
    i.type = "hidden";
    i.id = "encRequest";
    i.name = "encRequest";
    i.value = res;
    var ac = document.createElement("input");
    ac.type = "hidden";
    ac.id = "access_code";
    ac.name = "access_code";
    ac.value = loc;
    f.appendChild(i);

    f.appendChild(ac);

    document.body.appendChild(f);
    f.submit();
  };
  console.log(profileData);
  function handlePaymentClick(price, days) {
    const quantity = days
    const totalPrice = price * days;
 
    const form = {
      firstName: profileData.firstName,
      lastName: profileData.lastName,
      email: profileData.email,
      mobile: profileData.mobile,
      addressLine1: profileData.address.addressLine1,
      addressLine2: profileData.address.addressLine2,
      locality: profileData.address.locality,
      state: profileData.address.state,
      pinCode: profileData.address.pinCode,
    }
    ccAvanue(totalPrice, form, cart, quantity)
      .then((res) => {
        var data = {
          address: {
            addressLine1: profileData.address.addressLine1,
            addressLine2: profileData.addressLine2,
            locality: profileData.address.locality,
            pinCode: profileData.address.pinCode,
            state: profileData.address.state,
          },
          mobile: profileData.mobile,
          firstName: profileData.firstName,
          lastName: profileData.lastName,
        };

        dispatch(profileUpdate(data));
        ProceedForOrder(res);
      })
      .catch((err) => console.log("error->>>>", err));
  }
  return (
    <div className="container">
      {CalendarArray?.result?.length > 0 ? (
        <>
          <div className="calendar">
            <div className="front">
              <div className="current-month">
                <div className="weeks">
                  {CalendarArray?.result?.map((date, index) => {
                    return (
                      <div className="" key={date}>
                        {date?.map((i) => (
                          <span className={i?.delivered ? "active" : ""}>
                            {i?.date?.toLocaleString("en-US", {
                              minimumIntegerDigits: 2,
                              useGrouping: false,
                            })}
                          </span>
                        ))}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <Flex justify={"center"} flexDir={"column"} alignItems="center">
            <Text fontSize={"2xl"}>
              Already Subscribed to {currentProduct?.productName + " "}
              {currentProduct?.weight}
            </Text>

            <Text fontWeight={"bold"}>
              Total Price ₹
              {currentProduct.price * CalendarArray?.noOfDays?.length}
            </Text>
            <Button
              width={["80%", "80%", "70%", "70%"]}
              bg="#13acbc"
              color={"whitesmoke"}
              colorScheme={"blackAlpha"}
              // disabled={isInCart()}
              onClick={() => handlePaymentClick(currentProduct.price, CalendarArray?.noOfDays?.length)}
            >
              Pay Now
            </Button>
          </Flex>
        </>
      ) : (
        <Flex justify={"center"} flexDir={"column"} alignItems="center">
          <Text fontSize={"2xl"}>
            Already Subscribed to {currentProduct?.productName + " "}
            {currentProduct?.weight}
          </Text>
          <Text fontWeight={"bold"}>Data not found for the current month</Text>
        </Flex>
      )}
    </div>
  );
}
