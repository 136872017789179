export const GET_DATA_R = "GET_DATA_R";
export const GET_DATA_S = "GET_DATA_S";
export const GET_DATA_F = "GET_DATA_F";

export const UPDATE_DATA_R = "UPDATE_DATA_R";
export const UPDATE_DATA_S = "UPDATE_DATA_S";
export const UPDATE_DATA_F = "UPDATE_DATA_F";

export const DELETE_DATA_R = "UPDATE_DATA_R";
export const DELETE_DATA_S = "UPDATE_DATA_S";
export const DELETE_DATA_F = "UPDATE_DATA_F";
